import {Link} from "gatsby"
import React, { useState } from 'react';
import Img from "gatsby-image"
import YouTube from 'react-youtube';

const YoutubeClickableVideo = (props) => {
	
	
	const [show, setShow] = useState(false);
	
	const preview = (
	<div className="w-100 videoBg" style={{ backgroundImage: `url(${props.thumbnailUrl})` }}>
			<button onClick={e => setShow(true)} className="videoButton"><svg height="100%" version="1.1" viewBox="0 0 68 48" width="100%"><path class="" d="M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z" fill="#ff00ffbb" fill-opacity="0.8"></path><path d="M 45,24 27,14 27,34" fill="#fff"></path></svg></button>
	</div>
	)
	
	const video = (
			<YouTube videoId={props.videoId} containerClassName="embed-container" className="embed-container-iframe"
	    	opts={{
				height: null,
                width: null,
	    		playerVars: { // https://developers.google.com/youtube/player_parameters
	    			autoplay: 1,
	    			rel: 0,
	    			showinfo: 0,
	    			modestbranding: 1,
					mute: 1,
	    		}
	    	}}
	    />
	)

	if (!show) return (<>{preview}</>)
    return (<>{video}</>)
}


export default YoutubeClickableVideo
