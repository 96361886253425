import React from "react"
import {Col, Container, Row} from "react-bootstrap"
import Layout from "../components/layout"
import SEO from "../../plugins/gatsby-theme-chegatsby/src/components/seo"
import YoutubeClickableVideo from "../../plugins/gatsby-theme-chegatsby/src/components/youtubeClickableVideo"
import {graphql, useStaticQuery} from 'gatsby';
import {FaYoutube} from "react-icons/fa";

const VideoPortfolioPage = () => {
    const data = useStaticQuery(graphql`
query {
  allVideoListJson(sort: {fields: n})  {
    edges {
      node {
        id
		title
        videos {
          videoId
          thumbnailUrl
          title
        }
        
      }
    }
  }
}
    `)

    return (
        <Layout pageInfo={{pageName: "video-portfolio"}}>
            <SEO title="Home" keywords={[`video musica per matrimonio`, `pianobar per matrimonio`, `youtube matrimonio`]}/>
            <Container className="my-4 text-center">
                <h5 className="text-center">CIRO TRIASSI MUSIC LIVE</h5>
                <h1 className="mb-4">VIDEO</h1>
            </Container>
                {
					data.allVideoListJson.edges.map(cat => {
						
						const title = (
							<Container className="mt-4 text-center">
								<h5 className="mb-1">{cat.node.title}</h5>
								<hr/>
							</Container>
						)
						
						const videos = (
							<Row className="mx-1 text-center">							
								{
									cat.node.videos.map(video => (
											<Col key={video.videoId} className="my-4" sm={12} md={6} lg={4}>
												<h6 className={'video-title text-uppercase'}>{video.title}</h6>
												<YoutubeClickableVideo thumbnailUrl={video.thumbnailUrl} videoId={video.videoId} />
											</Col>
										)
									)
								}
							</Row>
						)
						
						return (
						<>{title} {videos}</>
						)
					})
				}
        </Layout>
    )
}

export default VideoPortfolioPage
